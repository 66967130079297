.what_we_do{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 10px;
}



@media screen and (max-width: 550px) {

}