body{
    background:white;
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
    .container{
        display: flex;
        justify-content: center;
    }
}
html {
    scroll-behavior: smooth;
}