.header__main{
	width: 100%;
	height: 75px;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0px 108px 0 108px;
	background: #f3f3f32d;
    backdrop-filter: blur(10px);
	box-shadow: 0 2px 4px rgba(20, 20, 20, 0.5);
    position: fixed;
    top: 0;
    z-index: 15;
    transition: 0.4s;
    &:hover .logo__header__main{
        filter: grayscale(0.0);
    }
    .logo__header__main{
        filter: grayscale(1.0);
        transition: 0.4s;
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
        .img__logo__header__main{
            margin: 2px;
            display: block;
            width: auto;
            height: 38px;
            min-height: 38px;
        }
        .p__logo__header__main{
            color: #01040b;
            // color: rgb(238, 238, 238);
            font-size: 18px;
            font-family: 'Lobster', sans-serif;
            margin: 0;
        }
    }
    .menu__header__main{
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
        .p__menu__header__main{
            color: #01040b;
            // color: rgb(238, 238, 238);
            text-decoration: none;
            font-size: 17px;
            font-family: 'Roboto', sans-serif;
            margin: 0;
            margin: 0px 16px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            &:active{
                color: #a1a1a1;
                // color: rgb(255, 255, 255);
                &::after{
                    width: 100% !important;
                }
            }
            &:hover{
                &::after{
                    width: 100% !important;
                }
            }
            &::after{
                content: '';
                width: 0%;
                position: absolute;
                bottom: 0px;
                height: 4px;
                background: #01040b;
                // background:rgb(238, 238, 238);
                transition: 0.2s;
                
            }
        }
    }
}


@media screen and (max-width: 550px) {
    .header__main{
        flex-direction: column;
        padding: 0;
        align-items: center;
        height: auto;
        position: absolute;
        .logo__header__main{
            width: 100vw;
            box-sizing: border-box;
            margin: 0;
            text-align: center;
            border-bottom: #8686863d solid 1px;
            padding: 12px;
            background: #d8d8d817;
            filter: grayscale(0.1);
            .img__logo__header__main{
                margin-top: 5px;
                
            }
            .p__logo__header__main{
                margin: 3px;
            }
        }
        .menu__header__main{
            flex-direction: column;
            align-items: center;
            text-align: center;
            display:inline-block;
            .p__menu__header__main{
                font-size: 21px;
                margin: 3px 0;
                padding: 12px 100px;
                display:inline-block;
                &:hover{
                    &::after{
                        width: 0 !important;
                    }
                }
            }
        }
    }
}