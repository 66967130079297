.item_what_we_do{
    width: calc(28% - 90px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin: 35px 45px;
    .img_con__what_we_do{
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        .img__item_what_we_do{
            height: 75px;
        }
    }
    .p__item_what_we_do{
        text-align: justify;
        color: #202020;
        font-family: "Poppins", Arial, sans-serif;
        font-size: 18px;
        padding: 4px;
        line-height: 1.4;
        margin: 0;
        font-weight: 400;
    }
}

@media screen and (max-width: 550px) {
    .item_what_we_do{
        width: 40%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        margin: 5px 5px;
    }
}