.footer{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px 0px;
    .motto_footer{
        text-decoration: none;
        width: calc(100% - 92px);
        border-bottom: 2px solid #f2f2f2;
        margin: 0;
        text-align: center;
        height: auto;
        padding: 28px 0px;
        padding-bottom: 22px;
        font-family: 'Playfair Display', serif;
        font-size: 26px;
        font-weight: 300;
        color: rgb(74, 74, 74);
        transition: 0.4s;
        &:hover{
            background: #0000000a;
        }
    }
    .back_to_top{
        text-transform: uppercase;
        font-weight: 700;
        color: black;
        padding: 14px 0px !important;
        font-size: 18px;
        display: flex;
        align-self: center;
        justify-content: center;
        align-self: center;
        &:hover{
            letter-spacing: 2px;
            color: #202020;
        }
        .back_to_top_img{
            height: 16px;
            margin-left: 12px;
            margin-top: 4px;
            transform: rotate(180deg);
        }
    }
    .main_footer{
        width: 100%;
        margin-top: 36px;
        margin-bottom: 6px;
        background: #f2f2f2;
        display: flex;
        padding: 28px 46px;
        box-sizing: border-box;
        justify-content: space-between;
        
        .contact__contacts_footer{
            color: black;
            font-family: "Poppins", Arial, sans-serif;
            font-size: 16px;
            padding: 6px;
            line-height: 1.8;
            margin: 0;
            font-weight: 400;
            .a_contact__contacts_footer{
                color: #905d17;
                text-decoration: none;
                transition: 0.4s;
                &:hover{
                    color: rgb(85, 65, 29)
                }
            }
        }
        .a{
            text-decoration: none;
            transition: 0.4s;
            color: #905d17;
            &:hover{
                color: rgb(85, 65, 29)
            }
        }
        .h2__contacts_footer{
            font-family: 'Playfair Display', serif;
            font-size: 30px;
            margin: 0;
            padding-bottom: 10px;
            font-weight: 300 !important;
            color: black;
        }
        .contacts_footer{
            
            .numbers__contacts_footer{
                display: flex;
                .m_left20{
                    margin-left: 20px;
                }
            }
        }
        .adress_contacts_footer{
            padding: 0px 18px;
            margin-right: 30px;
            border-left: 2px solid #dedede;
        }
    }
    .footer_bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        .p__footer_bottom{
            color: #727272;
            font-family: "Poppins", Arial, sans-serif;
            font-size: 14px;
            padding: 4px;
            margin-top: 4px !important;
            line-height: 1.9;
            margin: 0;
            font-weight: 500;
        }
    }
}



@media screen and (max-width: 550px) {
    .footer{
        .motto_footer{
            width: calc(100% - 5px);
            border-bottom: 2px solid #f2f2f2;
            padding: 18px 10px;
            font-size: 20px;
            box-sizing: border-box;
        }
        .main_footer{
            padding: 8px 18px;
            justify-content: center;
            flex-direction: column;
            margin-top: 25px;
            
            .contacts_footer{
            
                .numbers__contacts_footer{
                    display: flex;
                    flex-direction: column;
                    .m_left20{
                        margin-left: 0px;
                    }
                }
            }
            .adress_contacts_footer{
                padding: 0px;
                margin-right: 0px;
                border-top: 2px solid #dedede;
                border-left: 0px solid #dedede;
                padding-top: 15px;
                margin-top: 15px;
                padding-bottom: 15px;
            }
        }
        .footer_bottom{
            .p__footer_bottom{
                font-size: 12px;
                padding:6px;
                margin-top: 4px !important;
            }
        }
    }
}