.partners{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-around;
    padding: 20px 10px 20px 10px;
    margin-bottom: 60px;
    .con_partner{
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        margin: 0 10px;
        height: 110px;
        width: 200px;
        filter: saturate(0);
        transition: 0.5s;
        &:hover{
            filter: saturate(1);
        }
        .partner{
            object-fit: contain;
            height: 110px;
        }
        #partner_bigger_logo{
            height: 60px;
        }
    }
}
.motto__about_us{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .p__motto{
        width: 90%;
        padding: 32px 0;
        margin: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: 'Playfair Display', serif;
        font-size: 30px;
    }
}




@media screen and (max-width: 550px) {
    #partners{
        .p__motto{
            margin: 10px 0px !important;
            padding-bottom: 5px;
        }
    }
    .partners{
        flex-direction: column;
        .con_partner{
            width: auto;
            height: 100px;
            margin: 20px;
            .partner{
                object-fit: contain;
                height: 100px;
            }
            #partner_bigger_logo{
                height: 40px;
            }
        }
        margin-bottom: 10px;
    }
}