body{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    box-sizing: border-box;
    .font_smaller{
        font-size: 28px !important;
    }
    .motto__about_us{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .mbottom_0{
            margin-bottom: 0 !important;
        }
        .p__motto{
            width: 90%;
            padding: 32px 0;
            margin: 30px 0;
            display: block;
            text-align: center;
            font-family: 'Playfair Display', serif;
            font-size: 30px;
            .span_text{
                font-weight: 600;
            }
        }
    }
    .flex_end{
        justify-content: flex-end;
    }
    .section_main_info{
        display: flex;
        width: 100%;
        position: relative;

        .bg_img{
            position: absolute;
            z-index: -2;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            align-self: center;
            img{
                filter:blur(5px) saturate(0.7);
                align-self: center;
                object-fit: cover;
                height: 100%;
                width: 100%;
                transform: scale(1.1)  rotateY(180deg);
            }
        }
        .one_half{
            padding: 4% 7% 5% 4%;
            width: 60%;
        }
        .text_half{
            margin: 0;
            .text_main{
                padding: 40px;
                background: rgba(0, 0, 0, 0.5);
                .heading_section_text{   
                    .head__heading_section_text{
                        font-size: 50px;
                        display: block;
                        margin-bottom: 0;
                        font-family: "Great Vibes", cursive;
                        color: #c49b63;
                        line-height: 1;
                        margin-bottom: -21px;
                        z-index: 12;
                        position: relative;
                    }
                    .body__heading_section_text{
                        z-index: 0;
                        font-size: 40px;
                        margin: 0;
                        font-weight: 700;
                        color: whitesmoke;
                        position: relative;
                        font-family: "Josefin Sans", Arial, sans-serif;
                    }
                }
                .con__p_text_main{
                    .p_text_main{
                        font-family: "Poppins", Arial, sans-serif;
                        font-size: 18px;
                        line-height: 1.8;
                        font-weight: 300;
                        color: #e6e6e6;
                        .span_p_text_main{
                            text-decoration: none;
                            color: #c49b63;
                            transition: 0.4s;
                        }
                        .a_text_main{
                            &:hover{
                                color: #ababab;
                            }
                        }
                    }
                }
            }
        }
    }
}




@media screen and (max-width: 550px) {
    body{
        .motto__about_us{
            .p__motto{
                font-size: 26px !important;;
            }
            .font_smaller{
                padding: 0 24px;
            }
        }
        .section_main_info{
            .one_half{
                width: 100%;
                box-sizing: border-box;
                padding: 4% 4% 4% 4%;
                .text_main{
                    padding: 32px;
                    .heading_section_text{
                        .body__heading_section_text{
                            font-size: 30px;
                        }
                    }
                }
                
            }
        }
    }
}