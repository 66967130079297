.main_front{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    .video_con{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: -10;
        overflow: hidden;
        .bg_photo{
            min-width: 100%;
            min-height: 100vh;
            width: auto;
            height: auto;
            object-fit: cover;
            filter: blur(9px);
            transition: 0.2s;
            scale: 1.05;
        }
    }
    .text_part__main{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background: #06060631;
        .p_container{
            padding: 28px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            p{
                font-size: 44px;
                font-weight: 400;
                padding: 0;
                padding-bottom: 2px;
                margin: 0;
                color: white;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .p_main_name{
                font-size: 60px;
                font-weight: 700;
            }
            .p_main_motoo{
                color: rgba(230, 230, 230, 0.959);
            }
        }
    }
}



@media screen and (max-width: 550px) {
    .main_front{
        .text_part__main{
            .p_container{
                margin-top: 220px;
                .p_main_name{
                    font-size: 44px;
                    font-weight: 300;
                    padding: 0;
                    padding-bottom: 2px;
                    margin: 0;
                    color: white;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .p_main_motoo{
                    font-size: 44px;
                    font-weight: 300;
                    padding: 0;
                    padding-bottom: 2px;
                    margin: 0;
                    color: rgba(255, 255, 255, 0.859);
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 20px;
                }
            }
        }
    }
}